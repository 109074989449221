import React from "react";

class ClientsReview extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-paddings ptb_40 pt__0 bg-repeat">
          <div className="container">
            <div className="section-content">
              <div className="qpqq sdfghjkl-case">
                <div className="row align-items-center">
                  <div className="col-xl-9 col-lg-9 col-md-6">
                    <div className="bg-white mb__0">
                      <div className="case-banner">
                        <h2 class="ert678 mb__15">Client's Review</h2>
                      </div>
                      <p>
                        “The team at Shadow truly understood our brand and
                        translated it into a website that perfectly captures our
                        identity. The design is elegant, modern, and aligns
                        beautifully with our product offerings. They managed to
                        create a site that not only looks fantastic but also
                        speaks directly to our target audience. The attention to
                        detail in the design is incredible, and we've received
                        numerous compliments from our customers.”
                      </p>
                      <h6>Chandrakant Pandit, Owner</h6>
                      <p className="mb__0">Andosa Labs</p>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-6">
                    <div className=" bg-white ">
                      <img
                        src={require("./../../../images/andosa/client.webp")}
                        className="cnjf-cade"
                        alt="Shadow"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsReview;
