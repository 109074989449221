import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Designing Professional & User-Friendly Website",
    description:
      "Creating a professional and user-friendly website for a radiology services provider presented unique challenges. The website needed to serve multiple purposes i.e. providing detailed information about services, enabling easy contact details, offering resources for referring people, and building trust with potential clients. Balancing these requirements while ensuring the website was visually appealing and easy to navigate required careful planning and execution.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Creating High-Quality, Educational Content",
    description:
      "Developing high-quality, educational content that resonates with the healthcare professionals was critical to the success of the SEO strategy. We created a variety of content, including blog posts on radiology topics, and services. This content needed to be informative, accessible to a non-medical audience, and optimized for search engines to improve visibility and attract the right audience.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Identifying & Targeting Niche SEO Keywords",
    description:
      "The radiology sector is highly specialized, and identifying the right SEO keywords to target was a significant challenge. We performed in-depth keyword research to identify terms that potential patients and referring physicians were likely to use when searching for radiology services. This included targeting keywords related to specific diagnostic procedures, medical conditions, and local search terms. Balancing high competition with relevance and search volume required a strategic approach to improve search engine rankings effectively.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Enhancing Social Media Presence & Patient Engagement",
    description:
      "Social Media Optimization (SMO) presented its own set of challenges. We needed to create a social media strategy that not only promoted the radiology services but also engaged with healthcare professionals, and the broader community. This involved developing content that was both informative and shareable, such as educational posts on radiology procedures, and updates on the latest medical advancements. Balancing promotional content with educational and community-focused posts was key to building a strong social media presence.",
  },
];

class Challenges extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding ptb_40 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner mb__30">
                  <h2 class="ert678">The Challenges & The Solutions</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
