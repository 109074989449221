import React from "react";
import { NavLink } from "react-router-dom";

const teamMembers = [
  {
    image: require("./../../../images/16thAnniversary/banner_images.jpeg"),
    membername: "Celebrating 16th Anniversary",
    URL: "/16-Anniversary",
  },
  {
    image: require("./../../../images/lifeatshadow/img.jpg"),
    membername: "National Unity Day Celebration",
    URL: "https://www.facebook.com/media/set/?set=a.497209299093945&type=3",
  },
  {
    image: require("./../../../images/lifeatshadow/diwali.jpg"),
    membername: "Diwali Celebration 2022",
    URL: "https://www.facebook.com/media/set/?set=a.484503390364536&type=3",
  },
  {
    image: require("./../../../images/lifeatshadow/shadowimg2.png"),
    membername: "Cricket Team",
    URL: "https://www.facebook.com/media/set/?set=a.5422420597844043&type=3",
  },
  {
    image: require("./../../../images/lifeatshadow/image1.png"),
    membername: "Celebrating 14th Anniversary",
    URL: "https://www.facebook.com/media/set/?set=a.5371862122899891&type=3",
  },
  {
    image: require("./../../../images/lifeatshadow/image2.png"),
    membername: "Cycling Club",
    URL: "https://www.facebook.com/media/set/?set=a.5352447974841306&type=3",
  },
  {
    image: require("./../../../images/lifeatshadow/shadow-party2_22.jpg"),
    membername: "Christmas Celebration",
    URL: "https://www.facebook.com/media/set/?set=a.4621011411318303&type=3",
  },
  {
    image: require("./../../../images/lifeatshadow/shadow-party2_16.jpg"),
    membername: "Jenga Competition",
    URL: "https://www.facebook.com/shadowinfosystemlimited/photos/pcb.4584309218321856/4584279221658189/",
  },
  {
    image: require("./../../../images/lifeatshadow/shadow-party2_10.jpg"),
    membername: "Office Pooja",
    URL: "https://www.facebook.com/media/set/?set=a.4451842221568557&type=3",
  },
  {
    image: require("./../../../images/lifeatshadow/shadow-party2_05.jpg"),
    membername: "Stakeholder Awareness Programme-2021",
    URL: "https://www.facebook.com/shadowinfosystemlimited/photos/?tab=album&album_id=3602161603203294&ref=page_internal",
  },
  {
    image: require("./../../../images/web/GCTC-website-launch-img.webp"),
    membername: "GCTC Website Launch",
    URL: "https://www.facebook.com/media/set/?set=a.611082254373315&type=3",
  },
  {
    image: require("./../../../images/web/shadow-party2_12.webp"),
    membername: "Celebrating 11th Anniversary",
    URL: "https://www.facebook.com/shadowinfosystemlimited/photos/?tab=album&album_id=2378905608862239&__xts__%5B0%5D=68.ARBLTjHimseT4PlMpgnlDd5NmiuOpddPWpOoR136ZQvlVJBJ9EtX8QV0HQDb5o8PqkK4_ihMA8I0o-gYBpH2EF72uvqrRRHrvgSrWhwfQY57eNDaCGxMQQ5Zmj9sgQhTcq6cVe7s-OJN8WABHuAMmhfultKiSD2AUABFx00Vr1rlFo8Kul664pNebrFzCkjeiR6oN5GuKrASqfe9sV6aJUhwPywrRJUVJ0ol2rDhL0BWbjumXMHr7bRt78lQqHUUFlNWxReKtkw2lxo-hOrlRoBKmXBlK5KfzL0HxL7aHBWzEq47-UclrL7jxrYjq7yuB-VrJAU3INtbvBtGdvrEmIREKOFJKP94D3D2JCnNIYsOpc1TdEP81d7Y4BvBatR0BbE47jX1G-0ILT8-z3wEzgDp4HnD3z6I-RGoglOfnvRIbDZojGCFctRVm5RWrnoe9-Bs7ovpTI3eKJthdH33",
  },
  {
    image: require("./../../../images/web/shadow-party2_14.webp"),
    membername: "Book Launch Ceremony Of Data Sovereignty",
    URL: "https://www.facebook.com/shadowinfosystemlimited/photos/?tab=album&album_id=2209230785829723",
  },
  {
    image: require("./../../../images/web/shadow-party2_18.webp"),
    membername: "Excellence in IT Services",
    URL: "https://www.facebook.com/shadowinfosystemlimited/photos/?tab=album&album_id=2145487245537411",
  },
  {
    image: require("./../../../images/web/shadow-party2_21.webp"),
    membername: "DIPM Council of India App Launching",
    URL: "https://www.facebook.com/shadowinfosystemlimited/photos/?tab=album&album_id=2031049963647807",
  },
  {
    image: require("./../../../images/web/shadow-party2_04.webp"),
    membername: "Office Trip to Vaishno Devi",
    URL: "https://www.facebook.com/shadowinfosystemlimited/photos/?tab=album&album_id=1865736956845776",
  },
  {
    image: require("./../../../images/web/shadow-party2_01.webp"),
    membername: "10 Years Of Making An Impact",
    URL: "https://www.facebook.com/shadowinfosystemlimited/photos/?tab=album&album_id=1831313320288140",
  },
  {
    image: require("./../../../images/web/shadow-party2_08.webp"),
    membername: "CENJOWS iOS App Launching DRDO Bhawan",
    URL: "https://www.facebook.com/shadowinfosystemlimited/photos/?tab=album&album_id=1720947167991423",
  },
  {
    image: require("./../../../images/web/shadow-party2_14.webp"),
    membername: "Responsibility is the Price Of Freedom",
    URL: "https://www.facebook.com/shadowinfosystemlimited/photos/?tab=album&album_id=1794466990639440",
  },
  {
    image: require("./../../../images/web/shadow-party2_19.webp"),
    membername:
      "World Environment Day Trees Planting with Tehsil Bar Association",
    URL: "https://www.facebook.com/shadowinfosystemlimited/photos/?tab=album&album_id=1690991430986997",
  },
  {
    image: require("./../../../images/web/shadow-party2_03.webp"),
    membername: "Empowering Girls Education",
    URL: "https://www.facebook.com/shadowinfosystemlimited/photos/?tab=album&album_id=1662202183865922",
  },
  {
    image: require("./../../../images/web/shadow-party2_17.webp"),
    membername: "Ongoing Commitment and Dedicated Services Award at FICCI",
    URL: "https://business.facebook.com/shadowinfosystemlimited/photos?business_id=430191827336061&tab=album&album_id=1608327589253382",
  },
  {
    image: require("./../../../images/web/ashokaabh18.webp"),
    membername: "ABHYUDAY-18 at Ashoka Institute Varanasi, Shadow as Sponsor",
    URL: "https://www.facebook.com/shadowinfosystemlimited/photos/?tab=album&album_id=1604089813010493",
  },
  {
    image: require("./../../../images/web/shadow-party2_13.webp"),
    membername: "Indian Military Review Award",
    URL: "https://www.facebook.com/shadowinfosystemlimited/photos/?tab=album&album_id=1592460290840112",
  },
  {
    image: require("./../../../images/web/shadow-party2_24.webp"),
    membername: "CENJOWS App Launching",
    URL: "https://www.facebook.com/shadowinfosystemlimited/photos/?tab=album&album_id=1592456064173868",
  },
  {
    image: require("./../../../images/web/shadow-party2_20.webp"),
    membername: "Diwali Sweet Distribution",
    URL: "https://www.facebook.com/shadowinfosystemlimited/photos/?tab=album&album_id=1592437447509063",
  },
  {
    image: require("./../../../images/web/shadow-party2_09.webp"),
    membername: "Shadow 9th Anniversary",
    URL: "https://www.facebook.com/shadowinfosystemlimited/photos/?tab=album&album_id=1592417850844356",
  },
  {
    image: require("./../../../images/web/shadow-party2_15.webp"),
    membername: "Independence Day 2017",
    URL: "https://www.facebook.com/shadowinfosystemlimited/photos/?tab=album&album_id=1592429067509901",
  },
  {
    image: require("./../../../images/web/shadow-party2_26.webp"),
    membername: "Birthday Celebration",
    URL: "https://www.facebook.com/shadowinfosystemlimited/photos/?tab=album&album_id=1592422987510509",
  },
  {
    image: require("./../../../images/web/awardfunction-1.webp"),
    membername: "Employees Award Ceremony",
    URL: "https://www.facebook.com/shadowinfosystemlimited/photos/?tab=album&album_id=1592412140844927",
  },
  {
    image: require("./../../../images/web/cert-1.webp"),
    membername: "Certificate Distribution at SADRAG",
    URL: "https://www.facebook.com/shadowinfosystemlimited/photos/?tab=album&album_id=1592412140844927",
  },
  {
    image: require("./../../../images/web/shadow-party1-1.webp"),
    membername: "Proceedings of seminar on Social media & The Armed Forces",
    URL: "https://www.facebook.com/shadowinfosystemlimited/photos/?tab=album&album_id=1077160689036744",
  },
  {
    image: require("./../../../images/web/shadow-party2.webp"),
    membername: "Our Delighted Clients",
    URL: "https://www.facebook.com/shadowinfosystemlimited/photos/?tab=album&album_id=1514273628658779",
  },
];

var bgimg1 = require("./../../../images/background/cross-line2.png");

class Team1 extends React.Component {
  render() {
    return (
      <>
        <section class="section-4 p-t80 p-b50 mobile-page-padding back-ground-color">
          <div className="container">
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div className="">
                  <p>
                    <b>
                      Shadow is involved in a wide variety of community
                      development and environmental preservation projects.
                      Shadow social activities related to health, primary
                      education, skills training and entrepreneurship,
                      livelihood, women empowerment and strengthening services
                      for the differently-abled. Businesses are powerful
                      constituents of society and the most successful,
                      respected, and desirable businesses exist to do much more
                      than making money.
                    </b>
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              {teamMembers.map((item, index) => (
                <div className="col-md-4" key={index}>
                <a href={item.URL} target="_blank">
                  <figure class="figure">
                    <img src={item.image} alt={item.membername} />
                    <div>
                      <h3>
                        <span>{item.membername}</span>
                      </h3>
                      
                    </div>
                  </figure>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Team1;
