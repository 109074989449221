import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Designing a Technical yet Accessible Website",
    description:
      "Creating a website that effectively communicated the technical details of noise barrier solutions while remaining accessible to a diverse audience was a significant challenge. The website needed to present complex information about products, installation processes, and regulatory compliance in a clear and understandable way. We needed to balance technical accuracy with simplicity, ensuring that the website was user-friendly for both industry professionals and non-expert visitors seeking noise mitigation solutions.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Targeting Highly Specific SEO Keywords",
    description:
      "The noise barrier solutions market is highly specialized, and identifying the right SEO keywords presented a challenge. We needed to perform in-depth keyword research to identify terms that potential customers were likely to use when searching for noise barrier solutions. This included not only general terms related to noise control but also specific keywords related to materials, applications, and industry standards. The challenge was to balance highly specific keywords with broader search terms to maximize visibility without sacrificing relevance.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Technical SEO Challenges",
    description:
      "Technical SEO presented its own set of challenges, particularly given the complexity of the content and the need for precise organization. We had to ensure that the website was optimized for speed, mobile responsiveness, and search engine crawlers. This involved optimizing images, implementing schema markup, ensuring proper indexing, and managing site structure to make sure that all pages were easily accessible and well-ranked by search engines. Regular technical audits were necessary to identify and resolve issues that could affect the website's performance.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Local SEO and Industry Regulations",
    description:
      "Local SEO was particularly important for the noise barrier solutions provider, as many projects are geographically specific and subject to local regulations. We need to ensure that the website was optimized for local searches, which included setting up and optimizing the provider’s Google My Business profile, building local citations, and creating content that addressed local industry regulations and standards. Navigating these regulatory requirements and ensuring compliance in SEO practices added another layer of complexity to the project.",
  },
];

class Challenges extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding ptb_40 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner mb__30">
                  <h2 class="ert678">The Challenges & The Solutions</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
