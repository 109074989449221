import React from "react";

class ClientsReview extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-paddings ptb_40 pt__0 bg-repeat">
          <div className="container">
            <div className="section-content">
              <div className="qpqq sdfghjkl-case">
                <div className="row align-items-center">
                  <div className="col-xl-9 col-lg-9 col-md-12">
                    <div className="bg-white mb__0">
                      <div className="case-banner">
                        <h2 class="ert678 mb__15">Client's Review</h2>
                      </div>
                      <p>
                        “The website shadow infosystem developed for us is truly
                        outstanding. It’s professional, user-friendly, &
                        beautifully designed. Patients can easily find
                        information about both our skin & dental services,
                        schedule appointments, and access valuable resources.
                        The site’s design is clean and modern, and it has
                        significantly improved our online presence, making it
                        easier for new & existing patients to connect with us.”
                      </p>
                      <h6>Dr. Prativa Shrestha</h6>
                      <p className="mb__0">Glow Skin & Dental Clinic</p>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-6">
                    <div className=" bg-white ">
                      <img
                        src={require("./../../../images/glowskindental/client.webp")}
                        className="cnjf-cade"
                        alt="Shadow"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsReview;
