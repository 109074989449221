import React from "react";

class ClientsReview extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-paddings ptb_40 pt__0 bg-repeat">
          <div className="container">
            <div className="section-content">
              <div className="qpqq sdfghjkl-case">
                <div className="row align-items-center">
                  <div className="col-xl-9 col-lg-9 col-md-6">
                    <div className="bg-white mb__0">
                      <div className="case-banner">
                        <h2 class="ert678 mb__15">Client's Review</h2>
                      </div>
                      <p>
                        “What impressed me the most about Shadow was their
                        professionalism and responsiveness. They took the time
                        to understand our needs and were always available to
                        answer our questions and make adjustments as needed.
                        Their team kept us informed throughout the process, and
                        we felt supported every step of the way. It’s clear that
                        they are genuinely invested in our success.”
                      </p>
                      <h6>Ms. Priyanka Jain, Owner</h6>
                      <p className="mb__0">Vedam Pre-school</p>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-6">
                    <div className=" bg-white ">
                      <img
                        src={require("./../../../images/veedam/client.webp")}
                        className="cnjf-cade"
                        alt="Shadow"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsReview;
