import React from "react";

const AwardsList = [
  {
    image: require("./../../../images/award/award4-1.png"),
    position: "Excellence in IT Services Award 2018-19",
    review:
      "Excellence in IT Services Award 2018-19 National Security Series Dialogue India War Against Terror - What After Balakote? Winning moment for Team Shadow awarded for Excellence in IT Services Award 2018-19 by Air Chief Marshal AY Tipnis, PVSM, AVSM, VM (Retd), Former Chief of Air Staff, Maj Gen (Dr) GD Bakshi, SM, VSM (Retd), Air Marshal Ramesh Rai, VM (Retd), Air Marshal Anil Chopra, PVSM, AVSM, VM, VSM (Retd), and Maj Gen Ravi Arora (Retd), Chief Editor of the Indian Military Review. Work to make the Nation Proud! Nation First Jai Hind!",
  },
  {
    image: require("./../../../images/award/award1-1.png"),
    position: "Ongoing Commitment and Dedicated Services Award at FICCI",
    review:
      "Such a delighted moment at Federation House (FICCI) humbly accepted this Award with the greatest Appreciation for ONGOING COMMITMENT AND DEDICATED SERVICES AWARD by Lt. Gen Vinod Bhatia, PVSM, AVSM, SM (Retd), Director CENJOWS to Mr. Kumar Rajesh, CEO Shadow infosystem (P) Limited. We Team Shadow, Thanking you Lt. Gen Vinod Bhatia and also a big thanks to Smt. Nirmala Sitharaman, Minister of Defence India and Rashesh Shah, President FICCI to presented here for making the Massive Seminar. Recognition of the High Standard",
  },
  {
    image: require("./../../../images/award/award2-2.png"),
    position:
      "Recognition of the High Standard of Service Appreciation Award to Indian Military Review",
    review:
      "Congratulations on your success Mr. Kumar Rajesh (CEO) Shadow infosystem (P) Limited for achieved recognition of the high standard of service rendered to Indian Military Review(IMR)​ ​Appreciation ​Award on celebrations 8 years. A massive evening with General VK Singh (Retd), PVSM, AVSM, YSM, ADC, MP, Former Chief of Army Staff, Minister of State of External Affairs and Minister of state (independent charge), CEO and Maj Gen (Dr) GD Bakshi, SM, VSM (Retd), Indian Military Review You have made us all proud. Keep up the good work! Your dedication, enthusiasm and insight are really inspiring. ​We wish you many years of great achievements! No matter how big a crowd may be, a person like you always stands out! There's no one who has worked as hard as you have in the last years. Congrats!",
  },
  {
    image: require("./../../../images/award/award3-2.png"),
    position: "Appreciation Award by Indian Military Review",
    review:
      "Congratulations on your success Mr. Kumar Rajesh (CEO) Shadow infosystem (P) Limited for achieved recognition of the high standard of service rendered to Indian Military Review(IMR)​ ​Appreciation ​Award on celebrations 8 years. A massive evening with Maj Gen RK Arora (Retd) Indian Military Review We wish you many years of great achievements! No matter how big a crowd may be, a person like you always stands out! There's no one who has worked as hard as you have in the last years. Congrats!",
  },
  {
    image: require("./../../../images/gctc3/dvt10.webp"),
    position:
      "Award for Outstanding Contribution on National Unity Day Celebration",
    review:
      "We are honored to announce that Shadow infosystem has been awarded for its exceptional participation and sponsorship of the National Unity Day Celebration. This recognition is a testament to our commitment to fostering national unity and supporting events that promote solidarity and communal harmony. Our involvement in this significant event reflects our dedication to strengthening the fabric of our nation and contributing to the celebration of our shared values. We take pride in having played a pivotal role in this initiative and extend our heartfelt gratitude to all those who contributed to making the National Unity Day a memorable and impactful occasion.",
  },
];

function NewAwards() {
  return (
    <>
      <section id="gallery">
        <div class="container">
          <div class="row">
            {AwardsList.map((item, index) => (
              <div class="col-lg-4 mb-4">
                <div class="card br-5 mt-5">
                  <img
                    src={item.image}
                    alt={item.position}
                    class="card-img-top"
                  />
                  <div class="card-body">
                    <h5 class="card-title title-height">{item.position}</h5>
                    <p class="card-text of align-left">{item.review}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default NewAwards;
