import React from "react";
import videoBg from "./../../../images/dipm_council/mobile_app.mp4";

class VideoSection extends React.Component {
  render() {
    return (
      <>
        <div className="custom__video_section_with_video_bg">
          <div className="container">
            <video className="video__bg" autoPlay loop muted>
              <source src={videoBg} type="video/webm" />
            </video>

            <div className="video_bg_content_container"></div>
          </div>
        </div>
      </>
    );
  }
}

export default VideoSection;
