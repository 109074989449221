import React from "react";

class ClientsReview extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-paddings ptb_40 pt__0 bg-repeat">
          <div className="container">
            <div className="section-content">
              <div className="qpqq sdfghjkl-case">
                <div className="row align-items-center">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white mb__0">
                      <div className="case-banner">
                        <h2 class="ert678 mb__15">Client's Review</h2>
                      </div>
                      <p>
                        “I highly recommend Shadow infosystem to any
                        organization looking for expert website development.
                        Their professionalism, creativity, and dedication to
                        client success make them an exceptional partner.”
                      </p>
                      <h6>Major General (Dr) Ashok Kumar, Vsm (Retd)</h6>
                      <p className="mb__0">
                        Centre For Joint Warfare Studies (CENJOWS)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsReview;
