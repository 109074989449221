import React from "react";

class ClientsReview extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-paddings ptb_40 pt__0 bg-repeat">
          <div className="container">
            <div className="section-content">
              <div className="qpqq sdfghjkl-case">
                <div className="row align-items-center">
                  <div className="col-xl-9 col-lg-9 col-md-12">
                    <div className="bg-white mb__0">
                      <div className="case-banner">
                        <h2 class="ert678 mb__15">Client's Review</h2>
                      </div>
                      <p>
                        “From the beginning, Shadow infosystem team took the
                        time to thoroughly understand the unique aspects of our
                        radiology practice. They worked closely with us to
                        identify our goals, target audience, and the specific
                        services we wanted to highlight. Their in-depth
                        knowledge of the healthcare industry was evident, and
                        they crafted a strategy that perfectly aligned with our
                        vision.”
                      </p>
                      <h6>Dr. Ashok Chauhan, Managing Director</h6>
                      <p className="mb__0">Vital Radiology Services</p>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-6">
                    <div className=" bg-white ">
                      <img
                        src={require("./../../../images/vitalindia/client.webp")}
                        className="cnjf-cade"
                        alt="Shadow"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsReview;
