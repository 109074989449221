import React from "react";

class ClientsReview extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-paddings ptb_40 pt__0 bg-repeat">
          <div className="container">
            <div className="section-content">
              <div className="qpqq sdfghjkl-case">
                <div className="row align-items-center">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white mb__0">
                      <div className="case-banner">
                        <h2 class="ert678 mb__15">Client's Review</h2>
                      </div>
                      <p>
                        “Our website is not just a static repository of
                        information; it’s a dynamic tool that we can easily
                        update as our research evolves. The content management
                        system they implemented allows our team to publish new
                        reports, update data, and manage our blog with ease.
                        This flexibility has been crucial in keeping our content
                        fresh and relevant.”
                      </p>
                      <h6>Pradeep Aggarwal, Director</h6>
                      <p className="mb__0">DIPM Council</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsReview;
