import React from "react";

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding p-t80 p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner">
                  <h1 className="mb__30">Om Sai Driving School</h1>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 sx-separator-outer separator-center">
                <div className="case-logo custom__case_study_website_logo ">
                  <img
                    src={require("./../../../images/om_sai_driving/logo.webp")}
                    className=""
                    alt="Case Study Logo"
                  />
                </div>
                <div className="mxnv046">
                  <h5 className="dfghj876543">Om Sai Driving School</h5>
                  <ul className="scnv-case">
                    <li>
                      <b>Location : </b>
                      A-41, Sector 69, Transport Nagar Noida - 201301 Gautam
                      Budh Nagar (UP)
                    </li>
                    <li>
                      <b>Speciality : </b> Driving School
                    </li>
                    <li>
                      <b>Website : </b>{" "}
                      <a
                        href="https://omsaidrivingschool.in/"
                        class="text-white"
                        target="_blank"
                      >
                        www.omsaidrivingschool.in
                      </a>
                    </li>
                  </ul>

                  <div className="full-social-bg">
                    <h6 className="m-t0">Follow US: </h6>
                    <ul class="ertyupoi">
                      <li>
                        <a
                          href="https://www.facebook.com/osmdts.2003"
                          class="facebook"
                          target="_blank"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.instagram.com/osmdts_2003/"
                          class="instagram"
                          target="_blank"
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.linkedin.com/company/om-sai-motor-driving-training-school/"
                          class="linkedin"
                          target="_blank"
                        >
                          <i class="fa fa-linkedin"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.youtube.com/@osmdts_2003"
                          target="_blank"
                          class="youtube"
                        >
                          <i class="fa fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 sx-separator-outer">
                <div className="case-web-banner">
                  <img
                    src={require("./../../../images/om_sai_driving/mobile_banner.webp")}
                    className="phone-banner"
                    alt="Shadow"
                  />
                  <img
                    src={require("./../../../images/om_sai_driving/desktop_banner.webp")}
                    className="web-banner"
                    alt="Shadow"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="section-content">
              <div className="qpqq">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white">
                      <h2 class="ert678">Introduction</h2>
                      <p className="mb__0">
                        Om sai motor driving training school, a pioneering
                        institution with a rich legacy dating back to 2001.
                        Their mission is to equip individuals with the skills
                        and knowledge needed to navigate the roads safely and
                        responsibly. Over the years, they have earned a stellar
                        reputation as a comprehensive driving school, offering
                        training for light motor vehicles, high motor vehicles,
                        e-rickshaws, and hazardous goods transportation.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
