import React from "react";

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding p-t80 p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner">
                  <h1 className="mb__30">Greenvent</h1>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 sx-separator-outer separator-center">
                <div className="case-logo custom__case_study_website_logo small__logo">
                  <img
                    src={require("./../../../images/greenvent/logo.webp")}
                    className=""
                    alt="Shadow Case Study Logo"
                  />
                </div>
                <div className="mxnv046">
                  <h5 className="dfghj876543">Greenvent</h5>
                  <ul className="scnv-case">
                    <li>
                      <b>Location : </b>
                      43, Ecotech-12, Greater Noida, U.P - 201308
                    </li>
                    <li>
                      <b>Speciality : </b> Transformer Manufacturer
                    </li>
                    <li>
                      <b>Website : </b>{" "}
                      <a
                        href="https://greenvent.in/"
                        class="text-white"
                        target="_blank"
                      >
                        www.greenvent.in
                      </a>
                    </li>
                  </ul>

                  <div className="full-social-bg">
                    <h6 className="m-t0">Follow US: </h6>
                    <ul class="ertyupoi">
                      <li>
                        <a
                          href="https://www.facebook.com/greenventacoustic/"
                          class="facebook"
                          target="_blank"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.instagram.com/greenvent8/"
                          class="instagram"
                          target="_blank"
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.linkedin.com/company/greenvent-acoustic-pvt-ltd"
                          class="linkedin"
                          target="_blank"
                        >
                          <i class="fa fa-linkedin"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://twitter.com/greenvent8"
                          class="twitter"
                          target="_blank"
                        >
                          <img
                            src={require("./../../../images/twitter (1).png")}
                            class="county-logo1"
                            alt="Shadow infosystem"
                          />
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.youtube.com/@greenventacoustic"
                          target="_blank"
                          class="youtube"
                        >
                          <i class="fa fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 sx-separator-outer">
                <div className="case-web-banner">
                  <img
                    src={require("./../../../images/greenvent/mobile_banner.webp")}
                    className="phone-banner"
                    alt="Shadow"
                  />
                  <img
                    src={require("./../../../images/greenvent/desktop_banner.webp")}
                    className="web-banner"
                    alt="Shadow"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="section-content">
              <div className="qpqq">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white">
                      <h2 class="ert678">Introduction</h2>
                      <p className="mb__0">
                        Greenvent comes with the idea of innovative and
                        sustainable solutions for society. They focus on
                        implementing premium, innovative and eco-friendly
                        solutions for infrastructure development. They also
                        design, develop and execute sound barriers for outdoor
                        noise control. With more than two decades of combined
                        experience Greenvent had accomplished copious projects
                        for various industries like automobile, steel, power,
                        transport, health and hospitality.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
