import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Designing User-Friendly & Informative Website",
    description:
      "Designing a website that effectively communicated the driving school’s offerings while remaining user-friendly was a significant challenge. The website needed to provide clear information on courses options while making it easy for visitors to find what they were looking for quickly. Additionally, the design had to be mobile-responsive since many users would be accessing the site on their smartphones. We need to balance aesthetics with functionality, ensuring that the site was visually appealing yet straightforward and easy to navigate.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Addressing Technical SEO Requirements",
    description:
      "We also faced the challenge of ensuring that the driving school’s website was optimized for search engines from a technical standpoint. This involved improving site speed, ensuring proper URL structure, and implementing schema markup to help search engines better understand the content. Addressing issues like duplicate content, broken links, and crawl errors was essential for enhancing the website’s SEO performance. Regular technical audits and updates were necessary to maintain optimal site health and improve search engine rankings over time.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Competing Against Established Local Competitors",
    description:
      "The driving school industry is highly competitive, with many established schools already holding strong positions in local search rankings. We had to develop a strategy to help the driving school stand out among competitors. This involved identifying and emphasizing the school’s unique selling points, such as specialized courses, experienced instructors, and flexible scheduling options. The challenge was to craft messaging and content that highlighted these differentiators while aligning with the search intent of potential students.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Local SEO Optimization",
    description:
      "For a driving school, local SEO is crucial since most students search for schools within their area. We faced the challenge of optimizing the driving school’s website to rank well in local searches. This involved identifying and targeting location-specific keywords, optimizing Google My Business listings, and ensuring consistent and accurate local citations across various online directories. Additionally, we had to create content that appealed to local audiences, such as blog posts about driving tips, local traffic laws, and success stories from students in the area.",
  },
];

class Challenges extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding ptb_40 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner mb__30">
                  <h2 class="ert678">The Challenges & The Solutions</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
