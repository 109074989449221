import React from "react";

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding p-t80 p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner">
                  <h1 className="mb__30">SG Public School</h1>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 sx-separator-outer separator-center">
                <div className="case-logo custom__case_study_website_logo small__logo">
                  <img
                    src={require("./../../../images/sg_public/logo.webp")}
                    className=""
                    alt="Case Study Logo"
                  />
                </div>
                <div className="mxnv046">
                  <h5 className="dfghj876543">SG Public School</h5>
                  <ul className="scnv-case">
                    <li>
                      <b>Location : </b>
                      Sector-15, Vasundhara, Ghaziabad, UP - 201012
                    </li>
                    <li>
                      <b>Speciality : </b> Education
                    </li>
                    <li>
                      <b>Website : </b>{" "}
                      <a
                        href="https://sgpublicschool.in/"
                        class="text-white"
                        target="_blank"
                      >
                        www.sgpublicschool.in
                      </a>
                    </li>
                  </ul>

                  <div className="full-social-bg">
                    <h6 className="m-t0">Follow US: </h6>
                    <ul class="ertyupoi">
                      <li>
                        <a
                          href="https://www.facebook.com/sgpublicsrsecschool"
                          class="facebook"
                          target="_blank"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.instagram.com/sgpublicsrsecschool/"
                          class="instagram"
                          target="_blank"
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.linkedin.com/company/s-g-public-sr-sec-school/"
                          class="linkedin"
                          target="_blank"
                        >
                          <i class="fa fa-linkedin"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.youtube.com/@sgpublicschoolvasundhara"
                          target="_blank"
                          class="youtube"
                        >
                          <i class="fa fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 sx-separator-outer">
                <div className="case-web-banner">
                  <img
                    src={require("./../../../images/sg_public/mobile_banner.webp")}
                    className="phone-banner"
                    alt="Shadow"
                  />
                  <img
                    src={require("./../../../images/sg_public/desktop_banner.webp")}
                    className="web-banner"
                    alt="Shadow"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="section-content">
              <div className="qpqq">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white">
                      <h2 class="ert678">Introduction</h2>
                      <p className="mb__0">
                        S G Public Sr Sec School started in the modest way in
                        Vasundhara, Ghaziabad, in the year 1992, with the noble
                        aim of providing quality education. It is a
                        co-educational English medium school affiliated to
                        Central Board of Secondary Education (CBSE). It has many
                        laurels to its credit in Vasundhara viz. the first
                        English medium school, the first to be recognized by the
                        Government, the first to be allotted a 2 Acre regular
                        school plot. It is also affiliated to CBSE upto XII
                        Standard.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
