import React from "react";

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding p-t80 p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner">
                  <h1 className="mb__30">
                    HQIDS (Headquarters of Integrated Defence Staff)
                  </h1>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 sx-separator-outer separator-center">
                <div className="case-logo custom__case_study_website_logo small__logo">
                  <img
                    src={require("./../../../images/hqids/logo.webp")}
                    className=""
                    alt="Shadow Case Study Logo"
                  />
                </div>
                <div className="mxnv046">
                  <h5 className="dfghj876543">HQIDS</h5>
                  <ul className="scnv-case">
                    <li>
                      <b>Location : </b>
                      South Hutments, Kashmir House, Rajaji Marg, New
                      Delhi-110011
                    </li>
                    <li>
                      <b>Speciality : </b> Ministry of Defence, Government of India
                    </li>
                    <li>
                      <b>Website : </b>{" "}
                      <a
                        href="https://ids.nic.in/"
                        class="text-white"
                        target="_blank"
                      >
                        www.ids.nic.in
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 sx-separator-outer">
                <div className="case-web-banner">
                  <img
                    src={require("./../../../images/hqids/modvd.jpeg")}
                    className="phone-banner"
                    alt="Shadow"
                  />
                  <img
                    src={require("./../../../images/hqids/Screenshot 2024-09-13 122147.png")}
                    className="web-banner"
                    alt="Shadow"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="section-content">
              <div className="qpqq">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white">
                      <h2 class="ert678">Introduction</h2>
                      <p className="mb__0">
                        HQ IDS has been established as a part of MoD to carry
                        out the role and functions vide Government of India,
                        Ministry of Defence letter No
                        Mod/IC/1027/32/IDS/5843/2001 dated 23 Nov 2001. The
                        organization is headed by the chief of integrated
                        Defence Staff to the Chairman COSC (CISC) who enjoys a
                        status equivalent to that of Vice Chief of a Service.
                        The organization has representation from all three
                        services, MEA, DRDO and DoD and Def (Finance).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
