import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Identifying & Targeting the Right Keywords",
    description:
      "Identifying the right keywords to target in SEO was another significant challenge. The higher education market is highly competitive, with many institutions vying for visibility in search engine results. We performed extensive keyword research to identify terms that prospective students were likely to use when searching for colleges. This included a focus on program-specific keywords, location-based searches, and long-tail keywords related to unique aspects of the college. Balancing high competition with relevance and search volume required a strategic and nuanced approach.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Creating High-Quality, Relevant Content",
    description:
      "Developing high-quality, relevant content that resonates with a diverse audience was a major challenge. We needed to create a wide range of content for blog posts. This content needed to be engaging, informative, and optimized for search engines to improve visibility and attract prospective students. Additionally, maintaining a regular content schedule to keep the website fresh and updated was critical but resource-intensive.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Building a Strong Backlink Profile",
    description:
      "Building a strong backlink profile was essential for improving the college’s domain authority and search engine rankings. We faced some challenges in acquiring high-quality backlinks. This required strategic outreach and relationship-building efforts, as well as the creation of valuable, shareable content that other websites would want to link to.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Engaging with a Diverse Audience on Social Media",
    description:
      "Colleges must engage with a diverse audience on social media, including prospective students, current students, faculty, alumni, and the local community. We created a content strategy that catered to these different groups, ensuring that the college’s social media presence was vibrant, inclusive, and engaging. This involved creating a mix of content types, such as student stories, event highlights, academic achievements, and campus life glimpses, all while maintaining a consistent brand voice.",
  },
];

class Challenges extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding ptb_40 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner mb__30">
                  <h2 class="ert678">The Challenges & The Solutions</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
