import React from "react";

class ClientsReview extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-paddings ptb_40 pt__0 bg-repeat">
          <div className="container">
            <div className="section-content">
              <div className="qpqq sdfghjkl-case">
                <div className="row align-items-center">
                  <div className="col-xl-9 col-lg-9 col-md-6">
                    <div className="bg-white mb__0">
                      <div className="case-banner">
                        <h2 class="ert678 mb__15">Client's Review</h2>
                      </div>
                      <p>
                        “Throughout the website development process, Shadow was
                        incredibly responsive and supportive. They kept us
                        informed at every stage, welcomed our feedback, and made
                        adjustments quickly. Even after the launch, their
                        support has been excellent. We know we can rely on them
                        whenever we need assistance or want to explore new
                        features.”
                      </p>
                      <h6>Vijay Tikoo, Owner</h6>
                      <p className="mb__0">
                        Global Counter Terrorism Council (GCTC)
                      </p>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-6">
                    <div className=" bg-white ">
                      <img
                        src={require("./../../../images/gctc/client.webp")}
                        className="cnjf-cade"
                        alt="Shadow"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsReview;
