import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Designing an Intuitive & Feature-Rich Website",
    description:
      "One of the key challenges in enhancing the growth of our client was developing a website that was both visually appealing and functionally robust. The website needed to showcase the overview of the property and provide users with a seamless browsing experience. We had to ensure that the site was easy to navigate, mobile-friendly, and equipped with features accordingly. Balancing aesthetic design with functionality was critical to engaging visitors and converting them into leads.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Implementing a Targeted & Competitive SEO Strategy",
    description:
      "The real estate industry is highly competitive in terms of SEO, with numerous companies vying for top rankings on search engines. We had to develop a targeted SEO strategy that would help our client stand out in this crowded space. This involved conducting thorough keyword research to identify high-impact search terms related to the property, and locations. We needed to optimize the website for both broad and long-tail keywords while avoiding keyword cannibalization and maintaining relevance for local searches.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Creating High-Quality, Relevant Content",
    description:
      "Content creation can be one of another major challenge in enhancing the company's growth. We had to develop a content strategy that would attract and engage potential clients while also improving the website’s SEO performance. This included blog posts on real estate trends. The content needed to be informative, relevant, and optimized for search engines. Additionally, We had to ensure that the content was updated regularly to reflect the latest market developments and property listings.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Addressing Technical SEO Challenges",
    description:
      "Technical SEO is a crucial and critical aspect to the success of a real estate company. We had to ensure that the website was optimized for search engines from a technical standpoint. This involved optimizing site speed, ensuring mobile responsiveness, implementing structured data, and managing site architecture. Additionally, We had to address challenges related to duplicate content, crawl errors, and indexation issues that could negatively impact the website’s search engine rankings.",
  },
];

class Challenges extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding ptb_40 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner mb__30">
                  <h2 class="ert678">The Challenges & The Solutions</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
