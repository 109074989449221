import React from "react";

class ClientsReview extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-paddings ptb_40 pt__0 bg-repeat">
          <div className="container">
            <div className="section-content">
              <div className="qpqq sdfghjkl-case">
                <div className="row align-items-center">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white mb__0">
                      <div className="case-banner">
                        <h2 class="ert678 mb__15">Client's Review</h2>
                      </div>
                      <p>
                        “The team at Shadow was a pleasure to work with. They
                        took the time to understand our business and our goals,
                        and they delivered on what we desired. Their
                        communication was clear and timely, and they were always
                        available to answer our questions or make adjustments.
                        It’s clear that they are passionate about helping their
                        clients succeed, and their professionalism made the
                        entire process smooth and stress-free.”
                      </p>
                      <h6>Divyanshu Sharma, Owner</h6>
                      <p className="mb__0">Om Sai Driving School</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsReview;
