import React from "react";

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding p-t80 p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner">
                  <h1 className="mb__30">Vedam Pre School</h1>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 sx-separator-outer separator-center">
                <div className="case-logo custom__case_study_website_logo small__logo">
                  <img
                    src={require("./../../../images/veedam/logo.webp")}
                    className=""
                    alt="Shadow Case Study Logo"
                  />
                </div>
                <div className="mxnv046">
                  <h5 className="dfghj876543">Vedam Pre School</h5>
                  <ul className="scnv-case">
                    <li>
                      <b>Location 1 : </b>
                      NS-68, Sector Eta 1, Greater Noida, India, 201308
                    </li>
                    <li>
                      <b>Location 2 : </b>
                      HS-02, Sector Alpha 2, Greater Noida, India, 201308
                    </li>
                    <li>
                      <b>Speciality : </b> Education
                    </li>
                    <li>
                      <b>Website : </b>{" "}
                      <a
                        href="https://worldofvedam.com/"
                        class="text-white"
                        target="_blank"
                      >
                        www.worldofvedam.com
                      </a>
                    </li>
                  </ul>

                  <div className="full-social-bg">
                    <h6 className="m-t0">Follow US: </h6>
                    <ul class="ertyupoi">
                      <li>
                        <a
                          href="https://www.facebook.com/WorldOfVedam"
                          class="facebook"
                          target="_blank"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.instagram.com/worldofvedam/"
                          class="instagram"
                          target="_blank"
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.youtube.com/@worldofvedam2235/featured"
                          target="_blank"
                          class="youtube"
                        >
                          <i class="fa fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 sx-separator-outer">
                <div className="case-web-banner">
                  <img
                    src={require("./../../../images/veedam/mobile_banner.webp")}
                    className="phone-banner"
                    alt="Shadow"
                  />
                  <img
                    src={require("./../../../images/veedam/desktop_banner.webp")}
                    className="web-banner"
                    alt="Shadow"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="section-content">
              <div className="qpqq">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white">
                      <h2 class="ert678">Introduction</h2>
                      <p className="mb__0">
                        Your little bundle of joy has started taking baby steps,
                        some broken words have made the house chirpy and your
                        smartphone has quickly begun looking for the best play
                        school near you. The requirement is simple, a place
                        where your kid can play, learn, eat and yes, feel secure
                        and at home. Vedam Pre-school understand this and their
                        love for kids is as eternal and natural as yours as they
                        too are young parents like you. It is not just a play
                        school, it’s a green school and your kid’s second home.
                        At world of vedam, nature is your teacher, sand is your
                        blackboard, plants are your friends, and the entire
                        campus is your playground.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
