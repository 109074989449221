import React from "react";

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding p-t80 p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner">
                  <h1 className="mb__30">Symmetry Dentals</h1>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 sx-separator-outer separator-center">
                <div className="case-logo custom__case_study_website_logo small__logo">
                  <img
                    src={require("./../../../images/symmetry/logo.webp")}
                    className=""
                    alt="Shadow Case Study Logo"
                  />
                </div>
                <div className="mxnv046">
                  <h5 className="dfghj876543">Symmetry Dentals</h5>
                  <ul className="scnv-case">
                    <li>
                      <b>Location : </b>
                      1308 Sec 43, Near Paras Hospital, Sushant Lok, Gurgaon,
                      Haryana 122001
                    </li>
                    <li>
                      <b>Speciality : </b> Dental Care
                    </li>
                    <li>
                      <b>Website : </b>{" "}
                      <a
                        href="https://symmetrydentals.com/"
                        class="text-white"
                        target="_blank"
                      >
                        www.symmetrydentals.com
                      </a>
                    </li>
                  </ul>

                  <div className="full-social-bg">
                    <h6 className="m-t0">Follow US: </h6>
                    <ul class="ertyupoi">
                      <li>
                        <a
                          href="https://www.facebook.com/symmetrydentals"
                          class="facebook"
                          target="_blank"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.instagram.com/symmetrydental_cosmetic_clinic/"
                          class="instagram"
                          target="_blank"
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.linkedin.com/company/symmetry-dental-and-cosmetic-clinic"
                          class="linkedin"
                          target="_blank"
                        >
                          <i class="fa fa-linkedin"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.youtube.com/@symmetrydentals"
                          target="_blank"
                          class="youtube"
                        >
                          <i class="fa fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 sx-separator-outer">
                <div className="case-web-banner">
                  <img
                    src={require("./../../../images/symmetry/mobile_banner.webp")}
                    className="phone-banner"
                    alt="Shadow"
                  />
                  <img
                    src={require("./../../../images/symmetry/desktop_banner.webp")}
                    className="web-banner"
                    alt="Shadow"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="section-content">
              <div className="qpqq">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white">
                      <h2 class="ert678">Introduction</h2>
                      <p className="mb__0">
                        As the best dental clinic in Gurgaon, Symmetry Dentals
                        are committed to delivering an unparalleled experience
                        to our patients, characterized by a blend of warmth,
                        empathy, and individualized attention, all aimed at
                        ensuring your comfort as they work towards achieving
                        radiant smiles and optimal oral health. They
                        continuously update their techniques, employ the latest
                        products, and utilize cutting-edge equipment to ensure
                        that their patients receive nothing but the highest
                        standard of care, aligning with the evolving landscape
                        of modern dentistry.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
