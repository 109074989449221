import React from "react";

class ClientsReview extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-paddings ptb_40 pt__0 bg-repeat">
          <div className="container">
            <div className="section-content">
              <div className="qpqq sdfghjkl-case">
                <div className="row align-items-center">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white mb__0">
                      <div className="case-banner">
                        <h2 class="ert678 mb__15">Client's Review</h2>
                      </div>
                      <p>
                        “Since launching the new website and implementing the
                        SEO strategy, we’ve seen tangible results. Our online
                        presence has improved dramatically, and we’re reaching
                        more families than ever before. The increased visibility
                        has not only brought in new inquiries but has also
                        helped us build stronger connections with our existing
                        community.”
                      </p>
                      <h6>Manoj Gupta, Owner</h6>
                      <p className="mb__0">Aggarwal Homez</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsReview;
