import React from "react";
import videoBg from "./../../../images/dipm_council/mobile_app.mp4";

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding p-t80 p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner">
                  <h1 className="mb__30">DIPM Council</h1>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 sx-separator-outer separator-center">
                <div className="case-logo custom__case_study_website_logo small__logo">
                  <img
                    src={require("./../../../images/dipm_council/logo.webp")}
                    className=""
                    alt="Shadow Case Study Logo"
                  />
                </div>
                <div className="mxnv046">
                  <h5 className="dfghj876543">DIPM Council</h5>
                  <ul className="scnv-case">
                    <li>
                      <b>Location : </b>
                      C-333, Chittaranjan Park, New Delhi 110019
                    </li>
                    <li>
                      <b>Speciality : </b> Government Affliated Think Tank
                    </li>
                    <li>
                      <b>Website : </b>{" "}
                      <a
                        href="https://dipmcouncil.org/"
                        class="text-white"
                        target="_blank"
                      >
                        www.dipmcouncil.org
                      </a>
                    </li>
                  </ul>

                  {/* <div className="full-social-bg">
                    <h6 className="m-t0">Follow US: </h6>
                    <ul class="ertyupoi">
                      <li>
                        <a
                          href="https://www.facebook.com/gctcpage/"
                          class="facebook"
                          target="_blank"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.instagram.com/gctcworld/"
                          class="instagram"
                          target="_blank"
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.linkedin.com/company/global-counter-terrorism-council/"
                          class="linkedin"
                          target="_blank"
                        >
                          <i class="fa fa-linkedin"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://x.com/GCTCWORLD"
                          class="twitter"
                          target="_blank"
                        >
                          <img
                            src={require("./../../../images/twitter (1).png")}
                            class="county-logo1"
                            alt="Shadow infosystem"
                          />
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.youtube.com/channel/UC7N0RMSIpL-WuwW5jojnmeg"
                          target="_blank"
                          class="youtube"
                        >
                          <i class="fa fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-8 col-md-12 sx-separator-outer">
                <div className="case-web-banner">
                  <img
                    src={require("./../../../images/dipm_council/mobile_banner.webp")}
                    className="phone-banner"
                    alt="Shadow"
                  />
                  <img
                    src={require("./../../../images/dipm_council/desktop_banner.webp")}
                    className="web-banner"
                    alt="Shadow"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="section-content">
              <div className="qpqq">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6">
                    <div className="bg-white">
                      <h2 class="ert678">Introduction</h2>
                      <p className="mb__0">
                        Design Infrastructure Planning and Management (DIPM)
                        Society is a think tank working on voluntary and
                        honorary basis, and registered as non profit for society
                        for the benefit of Public in technical knowledge in
                        infrastructure sector. It members works purely on
                        honorary basis for the betterment of society .
                      </p>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6">
                    <div className="video___container">
                      <div className="container">
                        <video className="custom__video" controls loop>
                          <source src={videoBg} type="video/mp4" />
                        </video>

                        <div className="video_bg_content_container"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
