import React from "react";

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding p-t80 p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner">
                  <h1 className="mb__30">Andosa Lab</h1>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 sx-separator-outer separator-center">
                <div className="case-logo custom__case_study_website_logo small__logo">
                  <img
                    src={require("./../../../images/andosa/logo.webp")}
                    className=""
                    alt="Shadow Case Study Logo"
                  />
                </div>
                <div className="mxnv046">
                  <h5 className="dfghj876543">Andosa Lab</h5>
                  <ul className="scnv-case">
                    <li>
                      <b>Location : </b>
                      WZ-26, B1, Janakpuri, Delhi-110058
                    </li>
                    <li>
                      <b>Speciality : </b> Healtcare & Beauty
                    </li>
                    <li>
                      <b>Website : </b>{" "}
                      <a
                        href="https://andosalab.in/"
                        class="text-white"
                        target="_blank"
                      >
                        www.andosalab.in
                      </a>
                    </li>
                  </ul>

                  <div className="full-social-bg">
                    <h6 className="m-t0">Follow US: </h6>
                    <ul class="ertyupoi">
                      <li>
                        <a
                          href="https://www.facebook.com/glutaday2"
                          class="facebook"
                          target="_blank"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.instagram.com/glutaday2/"
                          class="instagram"
                          target="_blank"
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.youtube.com/@Glutaday"
                          target="_blank"
                          class="youtube"
                        >
                          <i class="fa fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 sx-separator-outer">
                <div className="case-web-banner">
                  <img
                    src={require("./../../../images/andosa/mobile_banner.webp")}
                    className="phone-banner"
                    alt="Shadow"
                  />
                  <img
                    src={require("./../../../images/andosa/desktop_banner.webp")}
                    className="web-banner"
                    alt="Shadow"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="section-content">
              <div className="qpqq">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white">
                      <h2 class="ert678">Introduction</h2>
                      <p className="mb__0">
                        Andosa Lab's premium range of makeup and skincare
                        products is designed to enhance your natural radiance
                        and empower your confidence. From revitalizing face
                        serums and lustrous hair serums to invigorating face
                        wash and hydrating moisturizers, their lineup is
                        meticulously crafted for your self-care journey.
                        Discover specialized solutions for skincare concerns,
                        including skin-lightening products, anti-acne creams,
                        and scar removers. Shield your skin with broad-spectrum
                        sunscreens and prioritize its health with their
                        anti-infective and anti-fungal formulations. At Andosa
                        Lab, they celebrate individuality and self-expression,
                        offering more than just beauty products; they offer a
                        journey to confidence.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
