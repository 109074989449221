import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg");

class About3 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-b50 p-t80 back-ground-color`}
        >
          <div className="container">
            <div className="section-content">
              <div className="qpqq">
                <div className="row">
                  <div className="col-xl-5 col-lg-5 col-md-12">
                    <div className="mnbvcx">
                      <h1 className="m-t0 m-b20 sx-tilte about-title">
                        Shadow <br />
                        Infosystem
                      </h1>
                      <h4 className="about0-sub">
                        Serving the Nation Since 2008
                      </h4>
                      <h6 className="about0-sub1">
                        An ISO 27001 : 2013 Certified Company
                      </h6>
                      <div className="col-lg-12 col-md-12 col-sm-6 m-b30">
                        <div class="row bertfv">
                          <div class="col-lg-6">
                            <div class="client-logo-pic1">
                              <div className="text-left">
                                <NavLink
                                  to="#"
                                  className="site-button btn-half"
                                >
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      viewBox="0 0 256 180"
                                    >
                                      <path
                                        fill="red"
                                        d="M250.346 28.075A32.18 32.18 0 0 0 227.69 5.418C207.824 0 127.87 0 127.87 0S47.912.164 28.046 5.582A32.18 32.18 0 0 0 5.39 28.24c-6.009 35.298-8.34 89.084.165 122.97a32.18 32.18 0 0 0 22.656 22.657c19.866 5.418 99.822 5.418 99.822 5.418s79.955 0 99.82-5.418a32.18 32.18 0 0 0 22.657-22.657c6.338-35.348 8.291-89.1-.164-123.134Z"
                                      />
                                      <path
                                        fill="#FFF"
                                        d="m102.421 128.06l66.328-38.418l-66.328-38.418z"
                                      />
                                    </svg>{" "}
                                    Watch Video
                                  </span>
                                </NavLink>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="client-logo-pic1">
                              <div className="text-left">
                                <NavLink
                                  to="#"
                                  className="site-button btn-half"
                                >
                                  <span>More About</span>
                                </NavLink>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7 col-md-12">
                    <div className="m-b30 bg-white">
                      <p>
                        Shadow is a cyber security and digital transformation
                        company. Our solutions are tailored to our client’s
                        needs and we employ the latest technologies to meet our
                        client’s goals. Our expert marketers, designers, and
                        developers know how to drive results. That’s why metrics
                        like leads and revenue generated are always in sharp
                        focus. Eventually, we are as successful as our clients
                        are. In Digital Transformation, our comprehensive
                        solutions combine strategies to increase visibility,
                        conversions, and revenue. In cyber security, our
                        problem-specific solutions are driven by the latest
                        technologies. We are muscling bad actors to give you a
                        safe and secure environment. We have complied with the
                        most stringent security requirements of the Ministry of
                        Defence, and the Ministry of Law Enforcement.
                      </p>
                      <p>
                        With ISO 27001:2013 certification, trusted by more than
                        1500+ customers, 16 excellence awards in IT services,
                        and having provided solutions to more than 40 diverse
                        business categories, we are ever more enthusiastic in
                        our commitment to providing a smooth experience on the
                        web and making it a safer place.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About3;
