import React from "react";

class ClientsReview extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-paddings ptb_40 pt__0 bg-repeat">
          <div className="container">
            <div className="section-content">
              <div className="qpqq sdfghjkl-case">
                <div className="row align-items-center">
                  <div className="col-xl-9 col-lg-9 col-md-6">
                    <div className="bg-white mb__0">
                      <div className="case-banner">
                        <h2 class="ert678 mb__15">Client's Review</h2>
                      </div>
                      <p>
                        “Thanks to their targeted SEO strategy, we’ve seen a
                        significant improvement in our search engine rankings.
                        Before working with Shadow infosystem, it was difficult
                        for prospective students to find us online. Now, we
                        consistently appear in the top search results for our
                        key programs and courses. This boost in visibility has
                        led to a noticeable increase in inquiries and
                        applications.”
                      </p>
                      <h6>Dr Pankaj Rajhans, Director</h6>
                      <p className="mb__0">Microtek Group Of Institutions</p>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-6">
                    <div className=" bg-white ">
                      <img
                        src={require("./../../../images/microtek/client.webp")}
                        className="cnjf-cade"
                        alt="Shadow"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsReview;
