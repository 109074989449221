import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Designing User-Friendly & Informative Website",
    description:
      "Creating a user-friendly and informative website for a school presented unique challenges. The website needed to serve as a central hub for various users, offering easy access to essential information like admissions, academic programs, calendars, and news updates. We designed a site that was both visually appealing and functional, incorporating features such as online application forms, and event management systems. Ensuring that the website was mobile-friendly and accessible to all users, including those with disabilities, was also critical.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Balancing Aesthetic Appeal with Functionality",
    description:
      "The challenge of balancing aesthetic appeal with functionality was significant. A school’s website must be professional yet welcoming, appealing to prospective students and parents while effectively communicating the school’s values and achievements. We needed to create a design that reflected the school’s identity, fostering a sense of trust and belonging among visitors, while also ensuring the site was easy to navigate and use.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Identifying & Targeting Educational SEO Keywords",
    description:
      "Targeting the right SEO keywords in the educational sector was another challenge. We needed to perform extensive keyword research to identify terms and phrases that prospective students and parents were likely to use. This required a strategic approach, focusing on location-specific keywords, academic programs, extracurricular activities, and school reputation. Balancing high-search volume with relevance to the school’s offerings was crucial to improving search engine rankings and attracting the right audience.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Optimizing for Technical SEO",
    description:
      "Technical SEO optimization was essential for enhancing the school’s online presence. We faced challenges such as improving website speed, ensuring mobile compatibility, implementing structured data, and enhancing site security. Regular technical audits and updates were necessary to address any issues and maintain optimal website performance, which is vital for higher search engine rankings and a positive user experience.",
  },
];

class Challenges extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding ptb_40 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner mb__30">
                  <h2 class="ert678">The Challenges & The Solutions</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
