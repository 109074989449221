import React from "react";

class ClientsReview extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-paddings ptb_40 pt__0 bg-repeat">
          <div className="container">
            <div className="section-content">
              <div className="qpqq sdfghjkl-case">
                <div className="row align-items-center">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white mb__0">
                      <div className="case-banner">
                        <h2 class="ert678 mb__15">Client's Review</h2>
                      </div>
                      <p>
                        “Working with Shadow infosystem has been an incredibly
                        positive experience for my dental practice. As a
                        healthcare provider, I knew the importance of having a
                        strong online presence, but I wasn’t sure where to
                        start. Shadow stepped in and transformed our digital
                        footprint with their expertise in website development
                        and SEO.”
                      </p>
                      <h6>Dr. Amit Aneja</h6>
                      <p className="mb__0">Owner, Symmetry Dentals</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsReview;
