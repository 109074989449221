import React from "react";

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding p-t80 p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner">
                  <h1 className="mb__30">Centre For Joint Warfare Studies</h1>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 sx-separator-outer separator-center">
                <div className="case-logo custom__case_study_website_logo small__logo">
                  <img
                    src={require("./../../../images/cen_jows/logo.webp")}
                    className=""
                    alt="Shadow Case Study Logo"
                  />
                </div>
                <div className="mxnv046">
                  <h5 className="dfghj876543">
                    Centre For Joint Warfare Studies
                  </h5>
                  <ul className="scnv-case mb__0">
                    <li>
                      <b>Location : </b>
                      R. No 301, B-2 Wing, 3rd Floor Pt Deendayal Antyodaya
                      Bhawan CGO Complex, Lodhi Road New Delhi – 110003
                    </li>

                    <li>
                      <b>Speciality : </b> Government Affliated Think Tank
                    </li>

                    <li className="pb__0 mb__0">
                      <b>Website : </b>{" "}
                      <a
                        href="https://cenjows.in/"
                        class="text-white"
                        target="_blank"
                      >
                        www.cenjows.in
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 sx-separator-outer">
                <div className="case-web-banner">
                  <img
                    src={require("./../../../images/cen_jows/mobile_banner.webp")}
                    className="phone-banner"
                    alt="Shadow"
                  />
                  <img
                    src={require("./../../../images/cen_jows/desktop_banner.webp")}
                    className="web-banner"
                    alt="Shadow"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="section-content">
              <div className="qpqq">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white">
                      <h2 class="ert678">Introduction</h2>
                      <p className="mb__0">
                        CENJOWS was raised at the initiative of ministry of
                        defence on 24 Aug 2007 and is registered under ‘The
                        Societies Registration Act 1860’. Their mission is to
                        promote integration as a synergistic enabler for the
                        growth of Integrated National Power and provide
                        alternatives in all dimensions of its applications
                        through focused research and debate.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
