import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Understanding Company's Mission & Vision",
    description:
      "One of the primary challenges we faced was gaining a deep understanding of the company's mission, vision, and objectives. The company operate at the intersection of research, analysis, and public engagement, requiring a website that effectively communicates their goals. We had to collaborate closely with the company’s leadership to ensure that the website reflected their values, research priorities, and target audiences. This involved translating abstract ideas and broad goals into concrete design elements and clear messaging, which was crucial for the website's effectiveness.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Showcasing Research and Publications",
    description:
      "Another significant challenge was how to best showcase the company's research and publications. They typically produce a wide range of content, including reports, policy briefs, articles, and data visualizations. We needed to develop a content management system (CMS) that could handle these diverse content types, making them easily searchable and accessible. Additionally, the website had to highlight the most recent and impactful research, while still providing access to an extensive archive of past work. The challenge was to present this information in a way that was both visually appealing and user-friendly, ensuring that visitors could quickly find the content relevant to their interests.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Ensuring Mobile Responsiveness & Accessibility",
    description:
      "In today's digital landscape, it was essential that the website made should be fully responsive and accessible on a variety of devices, including smartphones and tablets. We faced the challenge of ensuring that the site’s complex features, such as interactive data visualizations, multimedia content, and extensive publication libraries, were optimized for mobile users. Additionally, the website needed to comply with accessibility standards to ensure that all users could engage with the content. Balancing the need for advanced functionality with the requirement for broad accessibility and usability was a significant technical challenge.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Managing Data Security and Privacy",
    description:
      "They often handle sensitive information, including proprietary research and personal data from subscribers or event participants. Ensuring the security and privacy of this data was a critical challenge for us. The website needed to incorporate robust security measures, including secure login systems, data encryption, and compliance with relevant data protection regulations. The challenge was to implement these security features without compromising the website's performance or user experience which was achieved smoothly.",
  },
];

class Challenges extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding ptb_40 pb__20 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner mb__30">
                  <h2 class="ert678">The Challenges & The Solutions</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
