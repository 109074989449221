import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Designing A Comprehensive & User-Friendly Website",
    description:
      "Creating a website that effectively showcased both skin and dental services while maintaining a user-friendly experience was a significant challenge. We had to ensure that the website was organized in a way that allowed visitors to easily find information relevant to their specific needs, whether they were interested in dermatology or dental care. This involved creating clear navigation paths, developing separate sections for each specialization, and ensuring that the overall design was cohesive and visually appealing.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Creating High-Quality, Specialized Content",
    description:
      "Developing content that addressed the needs of patients in both skin care and dental care was another major challenge. We needed to create high-quality, informative content that was optimized for search engines and provided value to potential patients. This included writing blog posts, and educational articles that catered to each specialization. Ensuring that the content was both medically accurate and accessible to a general audience was critical to building trust and improving search engine rankings.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Addressing Technical SEO Challenges",
    description:
      "We faced challenges related to technical SEO, particularly given the dual specialization of the clinic. Ensuring that the website was technically sound, with optimized site speed, mobile responsiveness, and secure HTTPS protocols, was essential for improving the website’s search engine rankings. Additionally, managing structured data, schema markup, and proper indexing of content across both specializations required ongoing technical expertise and regular audits to identify and fix any issues that could hinder SEO performance.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Adapting to Evolving Search Engine Algorithms",
    description:
      "The ever-evolving nature of search engine algorithms posed a constant challenge for us. To maintain the clinic’s competitive edge, we had to stay updated on the latest SEO trends and algorithm changes. This required ongoing research, regular adjustments to SEO strategies, and the flexibility to adapt quickly to any changes that could impact the website’s rankings and visibility.",
  },
];

class Challenges extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding ptb_40 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner mb__30">
                  <h2 class="ert678">The Challenges & The Solutions</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
