import React from "react";

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding p-t80 p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner">
                  <h1 className="mb__30">Global Counter Terrorism Council</h1>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 sx-separator-outer separator-center">
                <div className="case-logo custom__case_study_website_logo small__logo">
                  <img
                    src={require("./../../../images/gctc/logo.webp")}
                    className=""
                    alt="Shadow Case Study Logo"
                  />
                </div>
                <div className="mxnv046">
                  <h5 className="dfghj876543">
                    Global Counter Terrorism Council
                  </h5>
                  <ul className="scnv-case">
                    <li>
                      <b>Location : </b>
                      B-30, G Floor, Sector-6, Gautam Budh Nagar, Noida
                      UP-201301, India
                    </li>
                    <li>
                      <b>Speciality : </b> Government Affliated Think Tank
                    </li>
                    <li>
                      <b>Website : </b>{" "}
                      <a
                        href="https://gctcworld.org/"
                        class="text-white"
                        target="_blank"
                      >
                        www.gctcworld.org
                      </a>
                    </li>
                  </ul>

                  <div className="full-social-bg">
                    <h6 className="m-t0">Follow US: </h6>
                    <ul class="ertyupoi">
                      <li>
                        <a
                          href="https://www.facebook.com/gctcpage/"
                          class="facebook"
                          target="_blank"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.instagram.com/gctcworld/"
                          class="instagram"
                          target="_blank"
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.linkedin.com/company/global-counter-terrorism-council/"
                          class="linkedin"
                          target="_blank"
                        >
                          <i class="fa fa-linkedin"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://x.com/GCTCWORLD"
                          class="twitter"
                          target="_blank"
                        >
                          <img
                            src={require("./../../../images/twitter (1).png")}
                            class="county-logo1"
                            alt="Shadow infosystem"
                          />
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.youtube.com/channel/UC7N0RMSIpL-WuwW5jojnmeg"
                          target="_blank"
                          class="youtube"
                        >
                          <i class="fa fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 sx-separator-outer">
                <div className="case-web-banner">
                  <img
                    src={require("./../../../images/gctc/mobile_banner.webp")}
                    className="phone-banner"
                    alt="Shadow"
                  />
                  <img
                    src={require("./../../../images/gctc/desktop_banner.webp")}
                    className="web-banner"
                    alt="Shadow"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="section-content">
              <div className="qpqq">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white">
                      <h2 class="ert678">Introduction</h2>
                      <p className="mb__0">
                        Global Counter Terrorism Council is a registered Non
                        profit Think Tank initiated by public spirited
                        individuals to awaken further issues of national
                        interest and global conscience about terrorism as a
                        threat to humanity, human security, global peace etc.
                        Members of different faculties and background like
                        former senior bureaucrats, academicians, former officers
                        from the arm forces and paramilitary services,
                        distinguished former diplomats, leading researcher,
                        media analysts, parliamentarians, human rights leaders
                        etc. provide support and regularly contribute with the
                        aim to deliberate on the root causes of Global Terrorism
                        and to present effective solutions to the problems faced
                        by society and the government.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
