import React from "react";
import { NavLink } from "react-router-dom";
import MoreInform from "./../MoreInform";
var bgimg1 = require("./../../../images/banner/web-development-banner.jpg");
// var bgimg2 = require('./../../../images/background/cross-line2.png');

class ClientsLogo2 extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full  mobile-page-padding dfbb7680978 p-t80 p-b10 bg-repeat ertyuioiu1"
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container ">
            <div className="row">
              <div className="col-md-7">
                <div className="section-head mnbv123">
                  <div className="sx-separator-outer ">
                    <div className="bg-repeat-x color-change ewgr8900">
                      <h3 className="ert678">
                        Loved by 1500+ customers all over the world
                      </h3>
                      <p>
                        Our greatest asset is the customers and clients
                        Satisfaction!
                      </p>
                    </div>
                  </div>
                  <MoreInform />
                </div>
              </div>
              <div className="col-md-5">
                <div className="mnbv1231">
                  <img
                    src={require("./../../../images/update1/clientele.webp")}
                    alt=""
                  />
                </div>
              </div>
            </div>
            {/* TITLE END */}
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo2;
