import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

class PopupHome extends React.Component {
  render() {

    return (
      <>  
        <div className="bts-popup" role="alert">
        <img className="vkovc-909 popupimaes" src={require("./../../../images/confetti (1).png")} alt="about_shadow" />
        <img className="vkovc-9091 popupimaes" src={require("./../../../images/confetti.png")} alt="about_shadow" />
        <img className="vkovc-9092 popupimaes" src={require("./../../../images/confetti.png")} alt="about_shadow" />
        <img className="vkovc-9093 popupimaes" src={require("./../../../images/confetti (1).png")} alt="about_shadow" />
        <img className="vkovc-9094 popupimaes" src={require("./../../../images/celebration-flag.png")} alt="about_shadow" />
         <div className="bts-popup-container">
           
             <div className="container vndccc54">
               <img src={require("./../../../images/popup_banner.jpeg")} alt="about_shadow" />
             </div>
                 <a href="#0" className="bts-popup-close img-replace">Close</a>
             </div>
        </div>
      </>
    );
  }
}

export default PopupHome;
