import React from "react";

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding p-t80 p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner">
                  <h1 className="mb__30">Glow Skin & Dental Clinic</h1>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 sx-separator-outer separator-center">
                <div className="case-logo custom__case_study_website_logo small__logo">
                  <img
                    src={require("./../../../images/glowskindental/logo.webp")}
                    className=""
                    alt="Shadow Case Study Logo"
                  />
                </div>
                <div className="mxnv046">
                  <h5 className="dfghj876543">Glow Skin & Dental Clinic</h5>
                  <ul className="scnv-case">
                    <li>
                      <b>Location : </b>
                      Second Floor, Indira Bhawan, Damkal Chakrapath,
                      Thadodhunga (Dhobighat), Lalitpur, Nepal
                    </li>
                    <li>
                      <b>Speciality : </b> Healthcare
                    </li>
                    <li>
                      <b>Website : </b>{" "}
                      <a
                        href="https://glowskindentalclinic.com.np/"
                        class="text-white"
                        target="_blank"
                      >
                        www.glowskindentalclinic.com.np
                      </a>
                    </li>
                  </ul>

                  <div className="full-social-bg">
                    <h6 className="m-t0">Follow US: </h6>
                    <ul class="ertyupoi">
                      <li>
                        <a
                          href="https://www.facebook.com/glowskindentalclinic/"
                          class="facebook"
                          target="_blank"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.instagram.com/skindrprativa/"
                          class="instagram"
                          target="_blank"
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.linkedin.com/company/glow-skin-dental-clinic/"
                          class="linkedin"
                          target="_blank"
                        >
                          <i class="fa fa-linkedin"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.youtube.com/channel/UCJTRep6XLcGqz0fGpn1nL3Q"
                          target="_blank"
                          class="youtube"
                        >
                          <i class="fa fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 sx-separator-outer">
                <div className="case-web-banner">
                  <img
                    src={require("./../../../images/glowskindental/mobile_banner.webp")}
                    className="phone-banner"
                    alt="Shadow"
                  />
                  <img
                    src={require("./../../../images/glowskindental/desktop_banner.webp")}
                    className="web-banner"
                    alt="Shadow"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="section-content">
              <div className="qpqq">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white">
                      <h2 class="ert678">Introduction</h2>
                      <p className="mb__0">
                        Glow Skin and Dental Clinic was founded by Dr. Prativa
                        Shrestha (Dermatologist) and Dr. Dilip Das
                        (Prosthodontist) to provide the best skin and dental
                        treatment under one roof from super-specialized,
                        internationally trained doctors applying
                        state-of-the-art technologies and innovation in a
                        hygienic environment. Their clinic’s spacious reception,
                        welcoming ambience, and staff make your family
                        comfortable while you receive your treatment, making the
                        treatment experience pleasurable for both you and your
                        family.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
