import React from "react";

class ClientsReview extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-paddings ptb_40 pt__0 bg-repeat">
          <div className="container">
            <div className="section-content">
              <div className="qpqq sdfghjkl-case">
                <div className="row align-items-center">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white mb__0">
                      <div className="case-banner">
                        <h2 class="ert678 mb__15">Client's Review</h2>
                      </div>
                      <p>
                        “From the start, the team at shadow infosystem took the
                        time to thoroughly understand our business and the
                        unique challenges we face. They delved into the
                        specifics of noise barrier solutions, including the
                        materials we use, the industries we serve, and the
                        regulatory environment we operate in. This deep industry
                        knowledge was evident in every aspect of the project,
                        from website development to SEO strategy.”
                      </p>
                      <h6>Sanjeev Kumar, CTO</h6>
                      <p className="mb__0">Greenvent</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsReview;
