import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const testimonialsSeo = [
  {
    image: require("./../../../images/glowskindental/seo_results/img_1.webp"),
  },
  {
    image: require("./../../../images/glowskindental/seo_results/img_2.webp"),
  },
  {
    image: require("./../../../images/glowskindental/seo_results/img_3.webp"),
  },
  {
    image: require("./../../../images/glowskindental/seo_results/img_4.webp"),
  },
  {
    image: require("./../../../images/glowskindental/seo_results/img_5.webp"),
  },
  {
    image: require("./../../../images/glowskindental/seo_results/img_6.webp"),
  },
];
const testimonialsSmo = [
  {
    image: require("./../../../images/glowskindental/smo_results/img_1.webp"),
  },
  {
    image: require("./../../../images/glowskindental/smo_results/img_2.webp"),
  },
  {
    image: require("./../../../images/glowskindental/smo_results/img_3.webp"),
  },
  {
    image: require("./../../../images/glowskindental/smo_results/img_4.webp"),
  },
];
class Social extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      margin: 30,
      nav: false,
      dots: false,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      responsive: {
        400: {
          items: 1,
        },
        540: {
          items: 1,
        },
        768: {
          items: 2,
        },
        991: {
          items: 3,
        },
        1350: {
          items: 3,
        },
      },
    };

    return (
      <>
        <div className="section-full mobile-page-padding ptb_40 bg-repeat">
          <div className="container mb__30">
            <div className="row">
              <div className="col-md-12">
                <div class="sx-separator-outer separator-left">
                  <div class="ertyui">
                    <h3 class="ert678">RESULTS ACHIEVED USING SEO</h3>
                  </div>
                </div>
                <OwlCarousel
                  className="owl-carousel testimonial-home-two owl-btn-vertical-center"
                  {...options}
                >
                  {testimonialsSeo.map((item, index) => (
                    <div className="item" key={index}>
                      <div className="hover-animation-1 bg-gray">
                        <div className="testimonial-detail clearfix">
                          <div className="scale-in-center dfgh34235">
                            <img src={item.image} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              </div>
            </div>
          </div>

          <div class="container">
            <div class="">
              <div class="row align-items-center">
                <div className="col-md-12">
                  <div class="sx-separator-outer separator-left">
                    <div class="ertyui">
                      <h3 class="ert678">SOCIAL HANDLE SCREENSHOT</h3>
                    </div>
                  </div>

                  <OwlCarousel
                    className="testimonial-home-two owl-btn-vertical-center"
                    {...options}
                  >
                    {testimonialsSmo.map((item, index) => (
                      <div className="item" key={index}>
                        <div className="hover-animation-1 bg-gray">
                          <div className="testimonial-detail clearfix">
                            <div className="scale-in-center dfgh34235">
                              <img src={item.image} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Social;
