import React from "react";

class ClientsReview extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-paddings ptb_40 pt__0 bg-repeat">
          <div className="container">
            <div className="section-content">
              <div className="qpqq sdfghjkl-case">
                <div className="row align-items-center">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white mb__0">
                      <div className="case-banner">
                        <h2 class="ert678 mb__15">Client's Review</h2>
                      </div>
                      <p>
                        “The results speak for themselves. We’ve seen a
                        significant increase in website traffic, higher search
                        engine rankings, and good quality leads. Shadow has not
                        only helped us enhance our online presence but has also
                        played a crucial role in driving our business growth.
                        Their expertise in website development and SEO has
                        positioned us to compete effectively in a highly
                        competitive market.”
                      </p>
                      <h6>Manoj Gupta, Owner</h6>
                      <p className="mb__0">Aggarwal Homez</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsReview;
