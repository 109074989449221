import React from "react";

class ClientsLogo1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding p-t80 p-b10 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner">
                  <h1 className="mb__30">E-sheesh Vapes</h1>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 sx-separator-outer separator-center">
                <div className="case-logo custom__case_study_website_logo small__logo">
                  <img
                    src={require("./../../../images/esheesh/logo.webp")}
                    className=""
                    alt="Shadow Case Study Logo"
                  />
                </div>
                <div className="mxnv046">
                  <h5 className="dfghj876543">E-sheesh Vapes</h5>
                  <ul className="scnv-case">
                    <li>
                      <b>Location : </b>
                      9-13, Holbrook Lane, Coventry, CV6 4AD
                    </li>
                    <li>
                      <b>Speciality : </b> Smoking Accessories
                    </li>
                    <li>
                      <b>Website : </b>{" "}
                      <a
                        href="https://e-sheesh.co.uk/"
                        class="text-white"
                        target="_blank"
                      >
                        e-sheesh.co.uk
                      </a>
                    </li>
                  </ul>

                  {/* <div className="full-social-bg">
                    <h6 className="m-t0">Follow US: </h6>
                    <ul class="ertyupoi">
                      <li>
                        <a
                          href="https://www.facebook.com/trishulengineering.co/"
                          class="facebook"
                          target="_blank"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.instagram.com/trishulengineering.co"
                          class="instagram"
                          target="_blank"
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.linkedin.com/company/trishul-engineering-co"
                          class="linkedin"
                          target="_blank"
                        >
                          <i class="fa fa-linkedin"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://x.com/trishulengco"
                          class="twitter"
                          target="_blank"
                        >
                          <img
                            src={require("./../../../images/twitter (1).png")}
                            class="county-logo1"
                            alt="Shadow infosystem"
                          />
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.youtube.com/@trishulengineeringco"
                          target="_blank"
                          class="youtube"
                        >
                          <i class="fa fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-8 col-md-12 sx-separator-outer">
                <div className="case-web-banner">
                  <img
                    src={require("./../../../images/esheesh/mobile_banner.webp")}
                    className="phone-banner"
                    alt="Shadow"
                  />
                  <img
                    src={require("./../../../images/esheesh/desktop_banner.webp")}
                    className="web-banner"
                    alt="Shadow"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="section-content">
              <div className="qpqq">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white">
                      <h2 class="ert678">Introduction</h2>
                      <p className="mb__0">
                        E-sheesh are not just an e-liquid reseller, their roots
                        stem from e-liquid manufacture and wholesaling. They
                        know their e-liquids and vape products, and what is a
                        good quality product, so they decided to open up their
                        resources and sell the best possible products from
                        around the world such as e-liquids, vape kits, mods and
                        tanks. They have a huge selection of e-liquid brands to
                        the masses, but also offer them at low costs with no
                        inflation, so in a nutshell global brands at low costs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
