import React from "react";

class ClientsReview extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-paddings ptb_40 pt__0 bg-repeat">
          <div className="container">
            <div className="section-content">
              <div className="qpqq sdfghjkl-case">
                <div className="row align-items-center">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white mb__0">
                      <div className="case-banner">
                        <h2 class="ert678 mb__15">Client's Review</h2>
                      </div>
                      <p>
                        “The website Shadow developed for us is nothing short of
                        exceptional. They designed a user-friendly, visually
                        appealing site that effectively communicates who we are
                        as a school. Features like online application forms,
                        event calendars, and school portal have made our
                        operations more efficient and accessible. The website is
                        also mobile-friendly, which is crucial for our community
                        of students, parents, and staff.”
                      </p>
                      <h6>Meenakshi</h6>
                      <p className="mb__0">Principal, SG Public School</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsReview;
