import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Capturing the Brand’s Unique Identity & Aesthetic",
    description:
      "One of the foremost challenges faced by us was accurately capturing the company’s unique brand identity and aesthetic. The beauty and healthcare industry places a strong emphasis on visual appeal, with branding playing a crucial role in consumer perception and trust. Our team had to work closely with the brand to ensure that the website’s design reflected its ethos, style, and values. This involved creating a visually stunning and cohesive design that resonated with the target audience, while also being functional and easy to navigate.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Managing Product Inventory & Content Updates",
    description:
      "Given the fast-paced nature of the beauty and healthcare industry, where product lines frequently change and new releases are common, the website needed to be easily updatable. We faced the challenge of developing a content management system (CMS) that allowed the beauty and healthcare products provider to quickly and efficiently update product information, and easily manage product inventory. This involved creating an intuitive backend system that could be managed by non-technical staff, ensuring that the website remained current and accurate without requiring constant technical support.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Ensuring Mobile Responsiveness & Cross-Platform Compatibility",
    description:
      "With a growing number of consumers shopping online via mobile devices, it was essential that the website be fully responsive and compatible across different platforms. We faced the challenge of optimizing the website for a variety of screen sizes and resolutions, ensuring that the user experience was consistent whether accessed on a smartphone, tablet, or desktop. This required careful planning and testing to ensure that the site’s design, features, and functionality performed flawlessly across all devices, providing a superior experience for all users.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Creating a Seamless User Experience (UX)",
    description:
      "The beauty and healthcare sector is highly competitive, with consumers expecting a seamless and engaging online experience. We faced the challenge of designing a user interface (UI) that was intuitive, easy to use, and optimized for conversion. This involved developing clear navigation paths, and ensuring that product pages were well-organized and informative. The goal was to minimize friction at every step of the customer journey, from product discovery to product inforamtion, thereby enhancing user satisfaction and boosting sales.",
  },
];

class Challenges extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding ptb_40 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner mb__30">
                  <h2 class="ert678">The Challenges & The Solutions</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
