import React from "react";

class ClientsReview extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-paddings ptb_40 pt__0 bg-repeat">
          <div className="container">
            <div className="section-content">
              <div className="qpqq sdfghjkl-case">
                <div className="row align-items-center">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="bg-white mb__0">
                      <div className="case-banner">
                        <h2 class="ert678 mb__15">Client's Review</h2>
                      </div>
                      <p>
                        “Your team clearly has a strong understanding of our
                        target audience and has been able to effectively engage
                        with them through creative and relevant content. The
                        results of the campaign have exceeded our expectations
                        and we’ve seen a significant increase in website traffic
                        and engagement. We appreciate your dedication and
                        flexibility in working with us to achieve our goals.”
                      </p>
                      <h6>Marketing Head</h6>
                      <p className="mb__0">E-sheesh Vapes</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsReview;
