import React from "react";

const challenges = [
  {
    number: <div class="number fontColor1">1</div>,
    heading: "Creating a Child-Friendly Yet Professional Website Design",
    description:
      "Designing a website for a preschool required striking the right balance between being child-friendly and maintaining a professional appearance. We created a website that was visually appealing to both children and parents, incorporating playful elements like bright colors and engaging images while ensuring that the site conveyed professionalism and trustworthiness. The challenge was to make the website inviting and warm without compromising on the clarity of important information, such as enrollment processes, curriculum details, and contact information.",
  },
  {
    number: <div class="number fontColor2">2</div>,
    heading: "Addressing Mobile Optimization and User Experience",
    description:
      "With many parents searching for preschools on their mobile devices, optimizing the website for mobile use was a critical challenge. We needed to ensure that the site was fully responsive, providing a seamless user experience across all devices. This involved simplifying navigation, ensuring quick load times, and making key information easily accessible on smaller screens. The challenge was to maintain the website's visual appeal and functionality while optimizing it for mobile users, who often make quick decisions based on their initial impressions of a site.",
  },
  {
    number: <div class="number fontColor3">3</div>,
    heading: "Implementing a Local SEO Strategy",
    description:
      "For a preschool, local SEO is crucial, as parents typically search for preschools within a specific geographic area. We took the challenge of optimizing the website for local search results, which involved targeting location-specific keywords, setting up and optimizing the preschool’s Google My Business profile, and ensuring consistent local citations. Additionally, We created locally relevant content, such as blog posts about local events, to enhance the preschool’s visibility in local search rankings.",
  },
  {
    number: <div class="number fontColor4">4</div>,
    heading: "Developing Content That Engages & Converts",
    description:
      "Creating content that effectively communicated the preschool’s values, educational approach, and benefits was one of the significant challenge. We needed to craft content that appealed to parents’ emotions while also providing clear, factual information about the preschool’s offerings. This included writing engaging content, developing informative sections on curriculum and facilities, and creating blog posts or articles that showcased the preschool’s expertise in early childhood education.",
  },
];

class Challenges extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding ptb_40 bg-repeat">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sx-separator-outer separator-center">
                <div className="case-banner mb__30">
                  <h2 class="ert678">The Challenges & The Solutions</h2>
                </div>
              </div>
              <div className="col-md-12 sx-separator-outer separator-center"></div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul class="infoGraphic">
                  {challenges.map((item, index) => (
                    <li>
                      <div class="numberWrap">
                        {item.number}
                        <div class="coverWrap">
                          <div class="numberCover"></div>
                        </div>
                      </div>
                      <div class="content">
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Challenges;
